import {firebase, correctedURL} from '../../utilities'

export default function base(state, dispatch, {saveToDB, store}, setlogoToState, customName) {
  //const statuses = state.files.map((file) => store(file)).filter((i) => i)
  let statuses
  if(customName !== null) {
    statuses = state.files.map((file) => store(new File([file], "logo.png", { type: file.type }))).filter((i) => i);
  }
  else {
    statuses = state.files.map((file) => store(file)).filter((i) => i)
  }


  //Follows this design https://www.fullstackfirebase.com/firebase-storage/notes
  return new Promise(function (resolve, reject) {
    let done = 0
    const l = state.files.length

    if (!statuses.length) resolve()

    for (let status of statuses) {
      status.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          dispatch({
            type: 'status',
            name: status.snapshot.ref.name,
            value: {progress, size: snapshot.totalBytes},
          })
        },
        (error) => reject(error),
        async () => {
          const {bucket, fullPath, name} = status.snapshot.ref
          const {totalBytes: size} = status.snapshot

          const s = {...state.statuses}
          delete s[name]
          dispatch({type: 'statuses', value: s})

          const payload = {
            bucket,
            fullPath,
            url: correctedURL(fullPath),
            name,
            size,
            created: firebase.firestore.Timestamp.now(),
          }

          await saveToDB(payload)
          setlogoToState && setlogoToState(payload) //DOE1-537-REOPEN

          done++
          if (done === l) resolve()
        }
      )
    }
  })
}

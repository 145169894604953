import React, {useState, useContext} from 'react'
import {Button, Modal, Select} from 'antd'
import {ReducerContext} from './reducer'
import convert, {convertActions} from './convert'
import getProjects from './getProjects'
import {saveAs} from 'file-saver'
import useResources from './useResources'
import DateItem from './DateItem'
import DB from '../DAL/DB'

const FormAction = DB.FormAction

export default function TopLevelState() {
  const [show, setShow] = useState(false)
  const {state, dispatch} = useContext(ReducerContext)

  function cancel() {
    dispatch({type: 'clear'})
    setShow(false)
  }

  async function finalize() {
    const {form, client, created, updated} = state
    if (!form) {
      console.info('Please select the form')
      return
    }

    const {data: actions} = await new FormAction(form).get()
    const strategies = actions
      .sort((a, b) => {
        return a.actionIndex - b.actionIndex
      })
      .map(({strategy, actionIndex}) => `${actionIndex} - ${strategy}`)

    console.info('Strategy Report:', {
      form,
      client,
      created,
      updated,
      strategies,
    })

    try{
    const response = await getProjects({form, client, created, updated})
    if(response && response.data) {
      const {data: projects} = response
      if(projects && projects.length > 0){
        const blob = convert(projects, strategies)
        saveAs(blob, 'strategy_report.csv')
        const actionBlob = convertActions(actions)
        saveAs(actionBlob, `actions_report.csv`)
      } else {
        alert('No projects available...')
      }
   } else {
     alert('No projects available...')
   }
  } catch(err){
    console.log('exception ', err.message)
    alert(err.message)
  }
    cancel()
  }

  // loads the user's organization's forms into state
  // useForms()
  // useRubrics(state.form)
  useResources()

  //console.log(state)

  return (
    <div>
      <Button onClick={() => setShow(true)} size="small">
        Create Strategy Report
      </Button>
      <Modal
        visible={show}
        onOk={finalize}
        onCancel={cancel}
        title={<h3>Create Strategy Report</h3>}
        width="80rem"
        okText="Generate"
        destroyOnClose={true}
      >
        <div>
          <div className="padding-bottom-2 full-width">
            <Select
              style={{width: '100%'}}
              placeholder="Select a Form"
              onChange={(value) => dispatch({type: 'form', value})}
            >
              {state.forms.map((f) => (
                <Select.Option value={f.id}>{f.title}</Select.Option>
              ))}
            </Select>
          </div>
          {/* client */}
          {state.clients.length > 0 && (
            <div className="padding-bottom-2">
              <Select
                style={{width: '100%'}}
                placeholder="Select a Client"
                onChange={(value) => dispatch({type: 'client', value})}
              >
                {state.clients.map((c) => (
                  <Select.Option value={c.id}>{c.name}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          <DateItem type="created" />
          <DateItem type="updated" />
        </div>
      </Modal>
    </div>
  )
}

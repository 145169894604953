import { store } from './utilities';
import DB from '../DAL/DB';
import { auth } from '../Auth';
import { Modal } from 'antd';

export const deleteField = (field, key) => ({ type: `delete${field}`, key });
export const category = (type) => (value) => ({ type, value });
export const activate = (key, value) => ({ type: 'active', key, value });
export const edit = (value) => ({ type: 'edit', value });
export const loadSection = (value) => ({ type: 'loadsection', value });
export const modified = (bool) => ({ type: 'modified', value: bool });
export const fieldDependency = (value) => {
  return { type: 'fieldDependency', value };
};

export const setField = (type) => (key, value) => ({ type, key, value });
export const modify = (type) => (k, v) => {
  store.dispatch(modified(true));
  return setField(type)(k, v);
};

const c = () => ({ type: 'clear' });

export const clear = () => {
  store.dispatch(modified(false));
  return c();
};

export const setCoverPageText = (text) => ({
  type: 'SET_COVER_PAGE_TEXT',
  payload: text,
});

export const setlogoToState = (text) => ({
  type: 'SET_LOGO',
  payload: text,
});

export const setKeysPageText = (text) => ({
  type: 'SET_KEYS_PAGE_TEXT',
  payload: text,
});

export const setCategoryDescriptionsText = (text) => ({
  type: 'SET_CATEGORY_DESCRIPTIONS_TEXT',
  payload: text,
});

export const setSummaryOfActionItemsText = (text) => ({
  type: 'SET_SUMMARY_OF_ACTION_ITEMS_TEXT',
  payload: text,
});

export const setAccessSettings = (settings) => ({
  type: 'SET_ACCESS_SETTINGS',
  payload: settings,
});

export const fetchRubricsByFormId = (formId) => async (dispatch) => {
  const response = await DB.Meta.getRubricsByFormId(formId);
  dispatch({ type: 'rubrics', payload: response });
};

export const fetchOrganizationsByUserId = () => async (dispatch) => {
  const response = await DB.Meta.getOrganizationsByUserId(auth.sunkaizenUser.id);
  dispatch({ type: 'clients', payload: response });
};

// Add action for skipReview
export const setSkipReview = (value) => ({
  type: 'SET_SKIP_REVIEW',
  payload: value,
});

// Action creator for saving special access settings
// Action creator for saving special access settings
export const saveSpecialAccess = (specialAccessData) => async (dispatch) => {
  try {
    const savedData = await DB.SpecialAccess.add(specialAccessData);

    // Dispatch success action
    dispatch({
      type: 'SAVE_SPECIAL_ACCESS_SUCCESS',
      payload: savedData, // include the saved data with id
    });

    // Show success message
    Modal.success({
      title: 'Success',
      content: 'Special access settings saved successfully.',
    });

    return { type: 'SAVE_SPECIAL_ACCESS_SUCCESS', payload: savedData }; // Return success result
  } catch (error) {
    console.error('Error saving special access settings:', error);

    // Dispatch failure action
    dispatch({
      type: 'SAVE_SPECIAL_ACCESS_FAILURE',
      payload: error.message,
    });

    // Show error message
    Modal.error({
      title: 'Error',
      content: error.message || 'Failed to save special access settings.',
    });

    return { type: 'SAVE_SPECIAL_ACCESS_FAILURE', payload: error.message }; // Return failure result
  }
};

// Fetch special access records by form ID
export const fetchSpecialAccessRecords = (formId) => async (dispatch) => {
  try {
    const records = await DB.SpecialAccess.getByFormId(formId);
    console.log("special records ::: " + JSON.stringify(records,null,2))
    dispatch({ type: 'FETCH_SPECIAL_ACCESS_SUCCESS', payload: records });
  } catch (error) {
    console.error('Error fetching special access records:', error);
  }
};

// Disable a special access record
export const disableSpecialAccessRecord = (recordId) => async (dispatch) => {
  try {
    await DB.SpecialAccess.disableRecord(recordId);
    dispatch({ type: 'DISABLE_SPECIAL_ACCESS_SUCCESS', payload: recordId });
  } catch (error) {
    console.error('Error disabling special access record:', error);
  }
};

// Enable a special access record
export const enableSpecialAccessRecord = (recordId) => async (dispatch) => {
  try {
    await DB.SpecialAccess.enableRecord(recordId);
    dispatch({ type: 'ENABLE_SPECIAL_ACCESS_SUCCESS', payload: recordId });
  } catch (error) {
    console.error('Error enabling special access record: ', error);
  }
};
import moment from 'moment'

function formatDate(date) {
  const m = moment(date)
  return m.format('MM-DD-YYYY')
}

function getSiteNotes(siteNotes = []) {
  return `"${siteNotes
    .map(({author, note}) => {
      return `${author}: ${note}`
    })
    .join('\n')}"`
}
// locality, postal_code, country, administrative_area_level_1
function getAddrComponent(address_components, type, short = false) {
  const component = address_components.find(({types}) => {
    return types.indexOf(type) > -1
  })
  if (component) {
    if (short) {
      return component.short_name
    } else {
      return component.long_name
    }
  }
  return ''
}

export const headerMap = (project) => {
  let address_components = []
  let addrComponents = ['', '', '', '']
  if (project.site?.geocode_results?.formatted_address) {
    addrComponents = project.site.geocode_results.formatted_address.split(', ')
    address_components = project.site.geocode_results.address_components
  }
  if (addrComponents.length < 4) {
    // complement address
    new Array(4 - addrComponents.length).forEach(() => {
      addrComponents = ['', ...addrComponents]
    })
  }

  // Calculate score
  let {pass, total, percentage_failure} = project.intakeResult?.rawData || {}
  let score = Math.floor((pass / total) * 100 * 10) / 10
  let passFail = ''

  const criticalPassed = Object.values(project.intakeResult?.criticalities || {}).every(
    ({actual, max}) => max === null || isNaN(max) || actual <= max
  )

  if (isNaN(score)) {
    score = ''
  } else {
    passFail = (pass / total) * 100 >= percentage_failure && criticalPassed ? 'Pass' : 'Fail'
  }
  const locationDetails = project.site?.geocode_results?.geometry?.location

  return {
    'Project ID': project.id,
    'Site Owner Name': project.site?.siteOwnerName,
    Address: addrComponents[0],
    'Latitude': locationDetails && locationDetails?.lat || '',
    'Longitude': locationDetails && locationDetails?.lng || '',
    /**
     The address components in this sample are based on a typical address format. Note that you might need to use a different set of components to align with the postal address formats used in some regions. For example, the sample code selects the locality component, which often represents the city part of the address. Examples of how components can differ include:

      In the UK and in Sweden, the component to display the city is postal_town.
      In Japan, components differ across prefectures.
      Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1.

     */
    City:
      getAddrComponent(address_components, 'locality') ||
      getAddrComponent(address_components, 'sublocality_level_1') ||
      getAddrComponent(address_components, 'administrative_area_level_3') ||
      getAddrComponent(address_components, 'postal_town') ||
      (getAddrComponent(address_components, 'country') == 'Puerto Rico'
        ? getAddrComponent(address_components, 'administrative_area_level_2') ||
          getAddrComponent(address_components, 'administrative_area_level_1')
        : ''),
    State: getAddrComponent(address_components, 'administrative_area_level_1', true),
    Zip: getAddrComponent(address_components, 'postal_code'),
    Country: getAddrComponent(address_components, 'country'),
    'Site State': (function () {
      if (project.site?.geocde_results) {
        return project.site.geocode_results.address_components.filter((c) =>
          c.types.includes('administrative_area_level_1')
        )[0].short_name
      }
      return null
    })(),
    'Site Rep Email': project.site?.siteRepEmail,
    'Site Rep Phone': project.site?.siteRepPhone,
    'Site Rep Name': project.site?.siteRepName,
    'Client Name': project.client?.name,
    'Client ID': project.client?.id,
    'Project Type/Form': project.intake?.title,
    'Project Rubric': (function () {
      // console.log(project.rubric)
      return (project.rubric || {}).name
    })(), // (project.rubric || {}).name,
    Status: project.status,
    'Installer Name': (project.installer || {}).name,
    'Installer ID': (project.installer || {}).id,
    'Installer Email': (project.installer || {}).email,
    'Inspector Name': (function () {
      if (!project.inspection) return ''
      if (!project.inspection.inspector) return ''
      return project.inspection.inspector.name
    })(),
    'Inspector Email': (function () {
      if (!project.inspection) return ''
      if (!project.inspection.inspector) return ''
      return project.inspection.inspector.email
    })(),
    'Reviewer Name': (project.reviewer || {}).name,
    'Reviewer Email': (project.reviewer || {}).email,
    'Site Notes': getSiteNotes(project.siteNotes),
    'Created Date': formatDate(project.created.toDate()),
    'Inspection Date': (function () {
      if (!project.inspection) return ''
      if (!project.inspection.date || !project.inspection.date.toDate) return ''
      return formatDate(project.inspection.date.toDate())
    })(),
    'Started Date': (function () {
      if (!project.startedAt || !project.startedAt.toDate) return ''
      return formatDate(project.startedAt.toDate())
    })(),
    'Completed/Last': (function () {
      const completed_on = project.completed_on || project.updated
      if (!completed_on || !completed_on._seconds) return ''
      return formatDate(completed_on._seconds * 1000)
    })(),
    'Updated Date': (function () {
      if (!project.updated || !project.updated.toDate) return ''
      return formatDate(project.updated.toDate())
    })(),
    'Reason for cancelling': Array.isArray(project.cancellations)
      ? project.cancellations
          .map(({reason, comment}) => {
            return `${reason}(${comment})`
          })
          .join(', ')
      : '',
    'Project Link': project.organization?.id
      ? `https://fitqm.ibts.org/organization/${project.organization.id}/projects/${project.id}/manage`
      : '',
    'Overall Score': score,
    'Pass/Fail Result': passFail,
  }
}

export const headers = [
  'Project ID',
  'Project Link',
  'Site Owner Name',
  'Address',
  'Latitude',
  'Longitude',
  'City',
  'State',
  'Zip',
  'Country',
  'Site Rep Email',
  'Site Rep Phone',
  'Site Rep Name',
  'Client Name',
  'Client ID',
  'Project Type/Form',
  'Project Rubric',
  'Status',
  'Installer Name',
  'Installer ID',
  'Installer Email',
  'Inspector Name',
  'Inspector Email',
  'Reviewer Name',
  'Reviewer Email',
  'Site Notes',
  'Created Date',
  'Inspection Date',
  'Started Date',
  'Completed/Last',
  'Updated Date',
  'Reason for cancelling',
]

export const intakeHeaders = ['Overall Score', 'Pass/Fail Result']

export const questionHeaders = [...headers, ...intakeHeaders]

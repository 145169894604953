import React, {useState, useContext} from 'react'
import {Button, Modal, Select} from 'antd'
import {ReducerContext} from './reducer'
import convert from './convert'
import getProjects from './getProjects'
import {saveAs} from 'file-saver'
import useResources from './useResources'
import DateItem from './DateItem'
import Forms from '../DAL/Forms'
import FormSection from '../DAL/FormSection'
import FormField from '../DAL/FormField'
const formModel = new Forms()

export default function TopLevelState() {
  const [show, setShow] = useState(false)
  const {state, dispatch} = useContext(ReducerContext)

  function cancel() {
    dispatch({type: 'clear'})
    setShow(false)
  }

  async function finalize() {
    const {rubric, form, client, created, updated} = state
    if (!rubric || !form) return

    const { data: projects } = await getProjects(form, rubric, client, created, updated)
    
    // Read form, sections and fields
    const formSection = new FormSection(form)
    const forms = await formModel.get(form)
    const formObj = forms.data[0]
    const sectionsArray = (await formSection.get()).data
    const sections = {}
    for (let section of sectionsArray) {
      const formField = new FormField(form, section.id)
      const fieldsArray = (await formField.get()).data
      const fields = {}
      fieldsArray.forEach((field) => {
        fields[field.id] = field
      })
      section.fields = fields
      sections[section.id] = section
    }

    formObj.sections = sections

    const blob = convert(projects, formObj, rubric)
    saveAs(blob, 'questions_report.csv')

    cancel()
  }

  // loads the user's organization's forms into state
  // useForms()
  // useRubrics(state.form)
  useResources()

  //console.log(state)

  return (
    <div>
      <Button onClick={() => setShow(true)} size="small">
        Create Questions Report
      </Button>
      <Modal
        visible={show}
        onOk={finalize}
        onCancel={cancel}
        title={<h3>Create Questions Report</h3>}
        width="80rem"
        okText="Generate"
        destroyOnClose={true}
      >
        <div>
          <div className="padding-bottom-2 full-width">
            <Select
              style={{width: '100%'}}
              placeholder="Select a Form"
              onChange={(value) => dispatch({type: 'form', value})}
            >
              {state.forms.map((f) => (
                <Select.Option value={f.id}>{f.title}</Select.Option>
              ))}
            </Select>
          </div>
          {state.forms.length > 0 && (
            <div className="padding-bottom-2">
              <Select
                style={{width: '100%'}}
                placeholder="Select a Rubric"
                onChange={(value) => dispatch({type: 'rubric', value})}
              >
                {state.rubrics.map((r) => (
                  <Select.Option value={r.id}>{r.name}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          {/* client */}
          {state.clients.length > 0 && (
            <div className="padding-bottom-2">
              <Select
                style={{width: '100%'}}
                placeholder="Select a Client"
                onChange={(value) => dispatch({type: 'client', value})}
              >
                {state.clients.map((c) => (
                  <Select.Option value={c.id}>{c.name}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          <DateItem type="created" />
          <DateItem type="updated" />
        </div>
      </Modal>
    </div>
  )
}
